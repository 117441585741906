import React from 'react';
import { graphql } from 'gatsby';

import Seo from 'components/container/Seo';
import SectionHero from 'components/container/SectionHero';
import PageBuilder from 'components/container/PageBuilder';

export default function TemplateHome({ data, pageContext }) {
  const url = pageContext?.url;
  const heroBackground = data?.heroBackground;
  const heroHeadlineImage = data?.heroHeadlineImage;
  const imageTextBackground = data?.imageTextBackground;
  const seoTitle = data?.craft?.entry?.seoTitle;
  const seoDescription = data?.craft?.entry?.seoDescription;
  const seoImage = data?.craft?.entry?.seoImage;
  const socialsBackground = data?.socialsBackground;

  const { pageBuilderNeo } = data?.craft?.entry;

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage?.[0]?.socialMediaUrl}
        url={url}
      />
      <SectionHero
        backgroundImage={heroBackground}
        heroHeadlineImage={heroHeadlineImage}
        heroTitleImageAlt="Bicchiere Tulpe pieno di birra tedesca Warsteiner Premium Pils bottiglia di birra"
      />
      <PageBuilder
        components={pageBuilderNeo}
        backgroundImage={imageTextBackground}
        backgroundImages={[imageTextBackground, socialsBackground]}
      />
    </>
  );
}

export const query = graphql`
  query QueryHomeIT($id: [Int]!, $siteId: Int) {
    craft {
      # entry for PageBuilder ImageText
      entry(id: $id, siteId: $siteId) {
        ... on Craft_HomeIt {
          title
          seoTitle
          seoDescription
          seoImage {
            socialMediaUrl: url(transform: transformSocialMedia)
          }
          pageBuilderNeo {
            ...PageBuilderQuery
          }
        }
      }
    }

    heroBackground: file(
      relativePath: { eq: "hero/warsteiner-hero-background-it.jpg" }
    ) {
      ...imageDefault
    }

    heroHeadlineImage: file(
      relativePath: { eq: "hero/serious-about-beer-hashtag.png" }
    ) {
      ...imageDefault
    }

    socialsBackground: file(relativePath: { eq: "bg-2.jpg" }) {
      ...imageDefault
    }

    socialsInstagramBackground: file(
      relativePath: { eq: "warsteiner-socials-instagram-background.jpg" }
    ) {
      ...imageDefault
    }

    socialsYoutubeBackground: file(
      relativePath: { eq: "warsteiner-socials-youtube-background.jpg" }
    ) {
      ...imageDefault
    }

    imageTextBackground: file(
      relativePath: { eq: "warsteiner-stage-content-bg.jpg" }
    ) {
      ...imageDefault
    }

    undercoverBossImage: file(
      relativePath: { eq: "Kampagnen/undercover-boss.jpg" }
    ) {
      ...imageDefault
    }
  }
`;
